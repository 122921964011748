<div class="style-new-password">
    <div class="d-flex align-items-center justify-content-center style-new-password__loading"
        [class.active]="isAuthenticating">
        <div class="spinner-mask">
            <div class="spinner-container">
                <mat-spinner diameter="44"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group ">
        <p id="hello">Esqueceu sua senha?</p>
        <p id="passwordDescription">
          Tudo bem. Informe o seu email cadastrado, e nós enviaremos um código de acesso temporário pra você.
        </p>
    </div>
    <form [formGroup]="newPasswordForm">
        <div class="form-group">
          <mat-form-field appearance="outline" class="form-login">
            <mat-label>Email</mat-label>
            <input matInput id="userName" type="text" placeholder="Seu email é o seu login" formControlName="userName">
          </mat-form-field>
          <div class="errors-message">
            <app-message-error class="invalido"
            *ngIf="newPasswordForm.get('userName').errors?.required && newPasswordForm.get('userName').touched"
            text="Este campo é obrigatório">
          </app-message-error>
          <app-message-error class="invalido"
            *ngIf="newPasswordForm.get('userName').errors?.email && newPasswordForm.get('userName').touched" text="Email inválido.">
          </app-message-error>
          </div>
        </div>

        <div class="form-row mb-2">
            <div class="login-feedback" [class.active]="isInvalid" *ngIf="isInvalid">
                E-mail inválido.  
            </div>
        </div>
    
        <div class="form-row buttonNewPassword">
          <div class="form-group col-md-6">
            <button id="btnNewPassword" mat-raised-button color="primary"
             [disabled]="newPasswordForm.invalid" type="submit" (click)="newPassword()"
              >PEDIR CÓDIGO TEMPORÁRIO</button>
          </div>
        </div>
      </form>

</div>