<div class="style-two-step">
    <div class="d-flex align-items-center justify-content-center style-two-step__loading"
        [class.active]="isAuthenticating">
        <div class="spinner-mask">
            <div class="spinner-container">
                <mat-spinner diameter="44"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group">
        <p id="title">Valide seu acesso ao Parceiro Fast Shop</p>
        <p id="subTitle">Escolha como quer receber o seu código para dupla autenticação de acesso.</p>
    </div>
    <div class="tiposAcesso">
        <!-- <div class="row smsAcess">
            <div>
                <button mat-raised-button id="circleSVG" disabled>
                    <img src="../../../../assets/svg/icon-outros-telephone-24px.svg" alt="sms">
                </button>
            </div>
            <div class="infosAcess">
                <p class="descTitle">SMS</p>
                <div class="row">
                    <P class="descSubtitle">No celular cadastrado:</P>
                    <P class="descSubtitleNumber">{{numberUser}}</P>
                </div>
            </div>
            <div class="goToTwoStepLogin">
                <button mat-icon-button id="btnGoToTwoStepLogin" (click)="GoToTwoStepLogin()">
                    <img src="../../../../assets/svg/icon-outros-seta_direita_24px.svg" alt="seta">
                </button>
            </div>
        </div>
        <hr> -->
        <div class="row tokenAcess">
            <div>
                <button mat-raised-button id="circleSVG" disabled>
                    <img src="../../../../assets/svg/icon-outros-email_24px.svg" alt="sms">
                </button>
            </div>
            <div class="infosAcess">
                <p class="descTitle">QR Code (Aplicativo Authenticator)</p>
                <div class="row">
                    <P class="descSubtitle">No aplicativo que utilizará para obter o código</P>
                </div>
            </div>
            <div class="goToTwoStepLogin">
                <button mat-icon-button id="btnGoToTwoStepLogin" (click)="geradorSecretQrCode()">
                    <img src="../../../../assets/svg/icon-outros-seta_direita_24px.svg" alt="seta">
                </button>
            </div>
        </div>
    </div>
</div>