<div class="menu">
  <mat-toolbar class="header-style sticky-top title-fixed" id="headerStyle">
    <mat-toolbar-row>
      <div class="row logoHeader" id="logoHeader">
        <div>
          <img src="./../../../../assets/svg/logo_parceiro_fast.svg" alt="Logo" id="logoParceiroFast">
        </div>
      </div>
      <span class="spacer"></span>
      <div class="row">
        <button mat-raised-button color="primary" id="pularIntro" (click)="goToHome()">PULAR APRESENTAÇÃO</button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="col-md-12 background-page">
    <div class="background-bem-vindo">
      <div class="row">
        <div class="col-md-6 left">
          <div class="form-group">
            <p id="hello">Bem Vindo ao</p><br>
            <p id="titleName">Parceiro Fast</p><br>
            <p id="bemVindoDescription">
              Nosso principal canal de gestão da vida do
                <a>Parceiro na Fast Shop.</a> Aqui você poderá
                se cadastrar, gerir e controlar toda a sua
                operação em nossos canais de venda.<br><br>
                Portal do parceiro Fast Shop, cuidando
                dos clientes por toda a vida e agora, dos
                nossos parceiros.
            </p>
          </div>
        </div>
        <div class="col-md-6 right">
          <img src="./../../../../assets/images/background_bem-vindo.png" alt="imagem bem vindo" class="imgBemVindo">
          <div class="ancora">
            <a href="bem-vindo/#conheca-a-fastshop"><img src="./../../../../assets/svg/scroll_bottom.svg" alt="ancora" class="ancoraDown"></a>
          </div>
        </div>
 
      </div>
    </div>
  </div>
  <div class="container margin-top conheca-a-fastshop">
    <div class="col-md-12">
      <div id="conheca-a-fastshop">
        <a href="conheca-a-fastshop">a</a>
      </div>
      <h1 id="title">Conheça a Fast Shop</h1>
      <P id="description">
        Por mais de 30 anos, a Fast vem ajudando seus clientes a posicionarem<br> suas marcas em um segmento premium no mercado
      </P>
    </div>
    <div class="row margin-top">
      <div class="col-md-4 icones-bem-vindo">
        <img src="./../../../../assets/svg/icon_market.svg" alt="icone mercado">
        <p class="tituloIcones">Mais de 100 Lojas</p>
        <p class="descricaoIcones">Presente em 9 estados e nos melhores shoppings do Brasil</p>
      </div>
      <div class="col-md-4 icones-bem-vindo">
        <img src="./../../../../assets/svg/icon_people.svg" alt="icone pessoas">
        <p class="tituloIcones">3º Melhor NPS</p>
        <p class="descricaoIcones">Maior índice de satisfação do varejo brasileiro</p>
      </div>
      <div class="col-md-4 icones-bem-vindo">
        <img src="./../../../../assets/svg/icon_medalha.svg" alt="icone medalha">
        <p class="tituloIcones">Principais Prêmios</p>
        <p class="descricaoIcones">A Fast Shop foi certificada com o “NPS Awards” em 2018.</p>
      </div>
    </div>
    <div class="col-md-12 title-mobile">
      <h1 id="titleTwo">Líder em Transformação Digital no Varejo</h1>
      <p id="subTitle">Referência em Market Place OmniChannel</p>
    </div>
    <div class="row apps-fastshop">
      <div class="col-md-4 app-fastshop">
        <h1 id="titleAppFast">App da Fast Shop</h1>
        <p id="descriptionAppFast">
          Um app especialmente preparado para oferecer a melhor experiência de compra do varejo.<br><br>
          Baixe o app nas lojas:<br>
        </p>
        <div class="align-img"><img src="./../../../../assets/svg/icon_apple_store.svg" alt="icone apple store" id="appleStore"></div>
        <div class="align-img"><img src="./../../../../assets/svg/icon_google_play.svg" alt="icone google play" id="googlePlay"></div>
      </div>
      <div class="col-md-4">
        <img id="imgApps" src="./../../../../assets/images/app_vendedor_2x.png" alt="app do vendedor">
      </div>
      <div class="col-md-4 app-colaborador">
        <h1 id="titleAppColaborador">App do Colaborador</h1>
        <div style="text-align: center;">
          <p id="descriptionAppColaborador">
            Seus produtos a disposição do melhor time de vendas do varejo brasileiro.<br><br> 
            Deseja fazer parte dessa história?<br>
            <a href="mailto:parceirofast@fastshop.com.br">Entre em contato aqui</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer-boas-vindas">
  <p id="footerText">® 2020 Fast Shop - Todos os direitos reservados.</p>
</footer>