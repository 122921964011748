<div class="change-password-signin">
    <div class="d-flex align-items-center justify-content-center change-password-signin__loading"
        [class.active]="isAuthenticating">
        <div class="spinner-mask">
            <div class="spinner-container">
                <mat-spinner diameter="44"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group ">
        <p id="hello">Criar senha</p>
        <p id="changePasswordDescription">
            Agora, vamos criar uma senha pra você. Ela deve ter o
            seguinte formato: pelo menos 8 caracteres, 1 letra
            maiúscula, 1 letra minúscula, 1 número e 1 caracter especial.
        </p>
    </div>

    <form [formGroup]="changePasswordForm">
        <div class="form-group mb-12">
            <mat-form-field appearance="outline" class="form-login">
                <mat-label>Nova senha</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" id="Password" type="password"
                    placeholder="Digite sua senha de acesso" formControlName="Password">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide" id="btnVisibleOnOff">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <div class="errors-message" id="errorSenha">
                <app-message-error class="invalido"
                    *ngIf="changePasswordForm.get('Password').errors?.required && changePasswordForm.get('Password').touched"
                    text="Este campo é obrigatório">
                </app-message-error>
                <app-message-error class="invalido"
                    *ngIf="changePasswordForm.get('Password').errors?.minlength && changePasswordForm.get('Password').touched"
                    text="Campo não está igual a senha ou não preenche as regras acima.">
                </app-message-error>
            </div>
        </div>
        <div class="form-group mb-12 form-login" id="divConfirmPass">
            <mat-form-field appearance="outline">
                <mat-label>Confirme sua nova senha</mat-label>
                <input matInput [type]="hideTwo ? 'password' : 'text'" id="confirmPassword" type="password"
                    placeholder="Digite sua senha de acesso" formControlName="confirmPassword">
                <button mat-icon-button matSuffix (click)="hideTwo = !hideTwo" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideTwo" id="btnConfirmVisibleOnOff">
                    <mat-icon>{{hideTwo ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <div class="errors-message">
                <app-message-error class="invalido"
                    *ngIf="changePasswordForm.get('confirmPassword').errors?.required && changePasswordForm.get('confirmPassword').touched"
                    text="Este campo é obrigatório">
                </app-message-error>
                <app-message-error class="invalido"
                    *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').invalid"
                    text="Campo não está igual a senha ou não preenche as regras acima.">
                </app-message-error>
            </div>
        </div>

        <div class="form-row mb-2">
            <div class="login-feedback" [class.active]="isInvalid" *ngIf="isInvalid">
                Senhas não conferem.
            </div>
        </div>

        <div class="form-row buttonChangePassword">
            <div class="form-group col-md-6">
                <button id="btnChangePassword" mat-raised-button color="primary" [disabled]="changePasswordForm.invalid"
                    type="submit" (click)="newPassword()">CRIAR NOVA SENHA</button>
            </div>
        </div>
    </form>
</div>