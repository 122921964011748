<div class="style-signin">
  <div class="d-flex align-items-center justify-content-center style-signin__loading" [class.active]="isAuthenticating">
    <div class="spinner-mask">
      <div class="spinner-container">
        <mat-spinner diameter="44"></mat-spinner>
      </div>
    </div>
  </div>
  <div class="form-group ">
    <p id="hello">Olá,</p>
    <p id="signinDescription">
      Bem vindo ao Parceiro Fast. No momento vamos pedir
      algumas informações básicas para criarmos sua conta.
    </p>
  </div>
  <form [formGroup]="loginForm">
    <div class="form-group">
      <mat-form-field appearance="outline" class="form-login">
        <mat-label>Email</mat-label>
        <input matInput id="userName" type="text" placeholder="Seu email é o seu login" formControlName="userName">
      </mat-form-field>
      <div class="errors-message">
        <app-message-error class="invalido"
          *ngIf="loginForm.get('userName').errors?.required && loginForm.get('userName').touched"
          text="Este campo é obrigatório">
        </app-message-error>
        <app-message-error class="invalido"
          *ngIf="loginForm.get('userName').errors?.email && loginForm.get('userName').touched" text="Email inválido.">
        </app-message-error>
      </div>
    </div>
    <div class="form-group mb-0">
      <mat-form-field appearance="outline" class="form-login">
        <mat-label>Senha</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="Password" type="Password"
          placeholder="Digite sua senha de acesso" formControlName="Password">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div class="errors-message">
        <app-message-error class="invalido"
          *ngIf="loginForm.get('Password').errors?.required && loginForm.get('Password').touched"
          text="Este campo é obrigatório">
        </app-message-error>
        <app-message-error class="invalido"
          *ngIf="loginForm.get('Password').errors?.minlength && loginForm.get('Password').touched"
          text="Senha deve conter pelo menos 8 caracteres">
        </app-message-error>
        <app-message-error class="invalido"
          *ngIf="loginForm.get('Password').invalid && loginForm.get('Password').touched"
          text="A senha deve conter pelo menos 8 caracteres, 1 letra minúscula, 1 letra maiúscula, 1 número e 1 caracter especial.">
        </app-message-error>
        <app-message-error *ngIf="loginForm.get('Password').untouched"
          text="Seu primeiro acesso? Utilize a senha enviada para o seu email.">
        </app-message-error>
        <app-message-error class="valido-blue" *ngIf="loginForm.get('Password').valid"
          text="Seu primeiro acesso? Utilize a senha enviada para o seu email.">
        </app-message-error>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="login-feedback" [class.active]="isInvalid" *ngIf="isInvalid">
        Email ou senha inválidos.
      </div>
    </div>

    <div class="form-row">
      <div class="form-group left col-md-6 esqueci-senha ">
        <a [routerLink]="['new-password']" style="text-decoration: none;" id="forgotPassword">Esqueci minha senha</a>
      </div>
      <div class="form-group right col-md-6">
        <button id="login" [className]="loginForm.invalid ? 'confirm-button-inactive' : 'confirm-button-active'"
          [type]="hide ? 'password' : 'text'" [disabled]="loginForm.invalid" type="submit" (click)="LogIn()"
          disabled>ENTRAR</button>
      </div>
    </div>
  </form>
  <div class="loginFastShop">
    <hr>
    <p id="OU">OU</p>
    <div class="btnLoginFastShop">
      <div class="form-group col-md-12">
        <a mat-button id="btnLoginFastShop" href="{{linkFastShopSSO}}"
          [disabled]="loginForm.get('userName').value !== '' || loginForm.get('Password').value !== ''" type="submit"
          (click)="LoginFastShop()"
          [className]="loginForm.get('userName').value !== '' || loginForm.get('Password').value !== '' ? 'button-inactive' : 'button-active'"
          disabled>
          FUNCIONÁRIO FAST SHOP (SSO)
        </a>

      </div>
    </div>
  </div>

</div>