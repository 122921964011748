import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class WorkFlowStatusService {

    constructor(
        private storageService: StorageService,
        private http: HttpClient
      ) {}

    
      public get WorkFlowStatus() {
        var sellerId = this.storageService.storage.getItem(`sellerId`);
        const headers = new HttpHeaders({
          accept: "application/json", 
          "seller-api-version": "v1",
          token: this.storageService.storage.getItem('key')
        })
        return this.http.get(`${environment.urlApigee}/sellercenter/v0/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/controlStatus`, {
          headers: headers
        });
      }
}
