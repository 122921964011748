<div class="style-two-step-signin">
    <div class="d-flex align-items-center justify-content-center style-two-step-signin__loading"
        [class.active]="isAuthenticating">
        <div class="spinner-mask">
            <div class="spinner-container">
                <mat-spinner diameter="44"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group">
        <p id="title">Valide seu acesso ao Parceiro Fast Shop</p>
        <p id="subTitle">Digite o código de 6 dígitos exibido no seu aplicativo:</p>
    </div>
    <form [formGroup]="twoStepForm">
        <div class="row">
            <div class="form-group align-inputs">
                <mat-form-field appearance="outline" class="form-login">
                    <input matInput (input)="changeValue($event, 'campo1')"  #campo1 id="numberOne" type="text" formControlName="numberOne" maxlength="1">
                </mat-form-field>
                <div class="errors-message">
                    <app-message-error class="invalido"
                        *ngIf="twoStepForm.get('numberOne').errors?.required && twoStepForm.get('numberOne').touched"
                        text="Este campo é obrigatório">
                    </app-message-error>
                </div>
            </div>
            <div class="form-group align-inputs">
                <mat-form-field appearance="outline" class="form-login">
                    <input matInput id="numberTwo" (input)="changeValue($event, 'campo2')" #campo2 type="text" formControlName="numberTwo" maxlength="1">
                </mat-form-field>
                <div class="errors-message">
                    <app-message-error class="invalido"
                        *ngIf="twoStepForm.get('numberTwo').errors?.required && twoStepForm.get('numberTwo').touched"
                        text="Este campo é obrigatório">
                    </app-message-error>
                </div>
            </div>
            <div class="form-group align-inputs">
                <mat-form-field appearance="outline" class="form-login">
                    <input matInput (input)="changeValue($event, 'campo3')" #campo3 id="numberThree" type="text" formControlName="numberThree" maxlength="1">
                </mat-form-field>
                <div class="errors-message">
                    <app-message-error class="invalido"
                        *ngIf="twoStepForm.get('numberThree').errors?.required && twoStepForm.get('numberThree').touched"
                        text="Este campo é obrigatório">
                    </app-message-error>
                </div>
            </div>
            <div class="form-group align-inputs">
                <mat-form-field appearance="outline" class="form-login">
                    <input matInput (input)="changeValue($event, 'campo4')" #campo4 id="numberFor" type="text" formControlName="numberFor" maxlength="1">
                </mat-form-field>
                <div class="errors-message">
                    <app-message-error class="invalido"
                        *ngIf="twoStepForm.get('numberFor').errors?.required && twoStepForm.get('numberFor').touched"
                        text="Este campo é obrigatório">
                    </app-message-error>
                </div>
            </div>
            <div class="form-group align-inputs">
                <mat-form-field appearance="outline" class="form-login">
                    <input matInput (input)="changeValue($event, 'campo5')" #campo5 id="numberFive" type="text" formControlName="numberFive" maxlength="1">
                </mat-form-field>
                <div class="errors-message">
                    <app-message-error class="invalido"
                        *ngIf="twoStepForm.get('numberFive').errors?.required && twoStepForm.get('numberFive').touched"
                        text="Este campo é obrigatório">
                    </app-message-error>
                </div>
            </div>
            <div class="form-group align-inputs">
                <mat-form-field appearance="outline" class="form-login">
                    <input matInput (input)="changeValue($event, 'campo6')"  #campo6 id="numberSix" type="text" formControlName="numberSix" maxlength="1">
                </mat-form-field>
                <div class="errors-message">
                    <app-message-error class="invalido"
                        *ngIf="twoStepForm.get('numberSix').errors?.required && twoStepForm.get('numberSix').touched"
                        text="Este campo é obrigatório">
                    </app-message-error>
                </div>
            </div>
        </div>
        <div class="row buttonSignIn">
            <div class="form-group col-md-12">
                <button id="btnLogin" mat-raised-button color="primary"
                 [disabled]="twoStepForm.invalid" type="submit" (click)="LogIn()">
                    ENTRAR
                </button>
              </div>
        </div>
        <div class="qrCodeStyle"
        *ngIf="secret !== '' && secret !== undefined && secret !== null">
            <p id="scanQrCode">Escaneie o seu QR Code para obter o código:</p>
            <div>
                <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="urlQrCode"
                cssClass="bshadow">
                </ngx-qrcode>
            </div>
        </div>
        <div class="row buttonBack">
            <div class="form-group col-md-12">
                <button [routerLink]="['']" id="btnBack" mat-stroked-button color="primary" type="submit">
                    VOLTAR
                </button>
              </div>
        </div>
    </form>

</div>