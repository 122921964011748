<div *ngIf="!isMobile">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="style-menu">
    <!-- Nó sem filhos -->
    <mat-tree-node [id]="generatedId(node.name)" #nodeElement class="style-mat-tree" *matTreeNodeDef="let node" matRipple
      matTreeNodePadding matTreeNodePaddingIndent="18" matRippleColor="#1351BF14"
      (click)="navigateToRoute(node,nodeElement)" [@simpleFade]="node.level?1:0">
      <div class="col-10 mr-5 row">
        <div class="status-circle {{ statusItem(node) }}">
        </div>
        <span id="menuName">
          {{node.name}}
        </span>
      </div>
  
    </mat-tree-node>
  
    <!-- Nó com filhos -->
    <mat-tree-node [id]="generatedId(node.name)" class="style-mat-tree" [class.expanded]="treeControl.isExpanded(node)"
      *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matRipple matRippleColor="#1351BF14" matTreeNodeToggle
      [@simpleFade]="node.level?1:0">
      <div class="col-10">
        <span>
          {{node.name}}
        </span>
      </div>
  
      <mat-icon class="mat-icon-rtl-mirror" [@fadeIn]="treeControl.isExpanded(node) ? 'dentro' : 'fora'">
        {{'chevron_right'}}
      </mat-icon>
  
    </mat-tree-node>
  
  
  </mat-tree>
</div>


<div *ngIf="isMobile">
  <div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="style-menu">
      <!-- Nó sem filhos -->
      <mat-tree-node [id]="generatedId(node.name)" #nodeElement class="style-mat-tree" *matTreeNodeDef="let node"
        matRipple matTreeNodePadding matTreeNodePaddingIndent="18" matRippleColor="#1351BF14"
        (click)="navigateToRoute(node,nodeElement)" [@simpleFade]="node.level?1:0">
        <div class="col-10 mr-5 row">
          <div class="status-circle {{ statusItem(node) }}">
          </div>
          <span id="menuName">
            {{node.name}}
          </span>
        </div>
      </mat-tree-node>
      <!-- Nó com filhos -->
      <mat-tree-node [id]="generatedId(node.name)" class="style-mat-tree"
        [class.expanded]="treeControl.isExpanded(node)" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
        matRipple matRippleColor="#1351BF14" matTreeNodeToggle [@simpleFade]="node.level?1:0">
        <div class="col-10">
          <span>
            {{node.name}}
          </span>
        </div>
        <mat-icon class="mat-icon-rtl-mirror" [@fadeIn]="treeControl.isExpanded(node) ? 'dentro' : 'fora'">
          {{'chevron_right'}}
        </mat-icon>
      </mat-tree-node>
    </mat-tree>
  </div>
  <div>
    <button mat-icon-button (click)="exitToApp()" class="buttonSair removeFocus" id="buttonSair"
      aria-label="Example icon-button with heart icon">
      <p>Sair</p>
    </button>
  </div>
</div>