<div class="style-forgot-password">
    <div class="d-flex align-items-center justify-content-center style-forgot-password__loading"
        [class.active]="isAuthenticating">
        <div class="spinner-mask">
            <div class="spinner-container">
                <mat-spinner diameter="44"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group">
        <p id="hello">Redefinir senha</p>
        <p id="passwordDescription">
            Com o código em mãos, crie uma nova senha com pelo 
            menos 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número
             e 1 caracter especial.
        </p>
    </div>
    <form [formGroup]="forgotForm">
        <div class="form-group">
          <mat-form-field appearance="outline" class="form-login">
            <mat-label>Email</mat-label>
            <input matInput id="userName" type="text" placeholder="Seu email é o seu login" formControlName="userName">
          </mat-form-field>
          <div class="errors-message">
            <app-message-error class="invalido"
            *ngIf="forgotForm.get('userName').errors?.required && forgotForm.get('userName').touched"
            text="Este campo é obrigatório">
          </app-message-error>
          <app-message-error class="invalido"
            *ngIf="forgotForm.get('userName').errors?.email && forgotForm.get('userName').touched" text="Email inválido.">
          </app-message-error>
          </div>
        </div>
        <div class="form-group code-width">
            <mat-form-field appearance="outline" class="form-login">
              <mat-label>Código temporário</mat-label>
              <input matInput id="conformationCode" type="text" placeholder="123456" formControlName="ConformationCode" maxlength="6" mask="000000">
            </mat-form-field>
            <div class="errors-message">
              <app-message-error class="invalido"
              *ngIf="forgotForm.get('ConformationCode').errors?.required && forgotForm.get('ConformationCode').touched"
              text="Este campo é obrigatório">
            </app-message-error>
            <app-message-error class="invalido"
              *ngIf="forgotForm.get('ConformationCode').errors?.email && forgotForm.get('ConformationCode').touched" text="Email inválido.">
            </app-message-error>
            </div>
          </div>
        <div class="form-group mb-12">
            <mat-form-field appearance="outline" class="form-login">
                <mat-label>Nova senha</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" id="password" type="password"
                    placeholder="Digite sua senha de acesso" formControlName="Password">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide" id="setVisibleOnOff" >
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <div class="errors-message">
                <app-message-error class="invalido"
                    *ngIf="forgotForm.get('Password').errors?.required && forgotForm.get('Password').touched"
                    text="Este campo é obrigatório">
                </app-message-error>
                <app-message-error class="invalido"
                    *ngIf="forgotForm.get('Password').errors?.minlength && forgotForm.get('Password').touched"
                    text="Campo não está igual a senha ou não preenche as regras acima.">
                </app-message-error>
            </div>
        </div>
        <div class="form-group mb-12">
            <mat-form-field appearance="outline" class="form-login">
                <mat-label>Confirme sua nova senha</mat-label>
                <input matInput [type]="hideTwo ? 'password' : 'text'" id="confirmPassword" type="password"
                    placeholder="Digite sua senha de acesso" formControlName="confirmPassword">
                <button mat-icon-button matSuffix (click)="hideTwo = !hideTwo" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideTwo" id="setConfirmVisibleOnOff">
                    <mat-icon>{{hideTwo ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <div class="errors-message">
                <app-message-error class="invalido"
                    *ngIf="forgotForm.get('confirmPassword').errors?.required && forgotForm.get('confirmPassword').touched"
                    text="Este campo é obrigatório">
                </app-message-error>
                <app-message-error class="invalido"
                *ngIf="forgotForm.get('confirmPassword').errors?.MatchPassword && forgotForm.get('confirmPassword').touched"
                text="Campo não está igual a senha ou não preenche as regras acima.">
            </app-message-error>
            </div>
        </div>

        <div class="form-row mb-2">
            <div class="login-feedback" [class.active]="isInvalid" *ngIf="isInvalid">
                Código temporário incorreto. Digite novamente.  
            </div>
        </div>
        <div class="form-row mb-2">
            <div class="login-feedback" [class.active]="isInvalid" *ngIf="isInvalidTwo">
                Código temporário expirou. 
            </div>
        </div>
    
        <div class="form-row buttonNewPassword">
          <div class="form-group col-md-12">
            <button id="btnNewPassword" mat-raised-button color="primary"
             [disabled]="forgotForm.invalid" type="submit" (click)="confirmNewPassword()"
              >ENTRAR</button>
          </div>
        </div>
      </form>


</div>