import { Component, HostListener } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { files } from './example-data';
import { MenuNavigationService } from 'src/app/services/menu-navigation.service';

import FileNode from './models/file-node';
import FlatTreeNode from './models/flat-tree-node';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
import { WorkFlowStatusService } from 'src/app/services/work-flow-status.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [
    trigger('simpleFade', [
      transition('*=>1', [style({ opacity: 0 }), animate(350)]),
    ]),
    trigger('fadeIn', [
      state(
        'fora',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'dentro',
        style({
          transform: 'rotate(90deg)',
        })
      ),
      transition('fora=>dentro', [animate('.2s ease-out')]),
      transition('dentro=>fora', [animate('.2s ease-out')]),
    ]),
  ],
})
export class LeftMenuComponent {
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  statusList: any[] = [];
  statusCadastro: boolean = false;
  public isMobile: boolean;

  constructor(
    private menuNavigationService: MenuNavigationService,
    private workFlowStatusService: WorkFlowStatusService,
    private deviceService: DeviceDetectorService,
    private authService: AuthService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  ngOnInit() {
    this.menuNavigationService.getUserMenuItems().subscribe(
      (response:any[]) => {
        // @ts-ignore: Property 'flatMap' does not exist on type 'any[]'
        this.dataSource.data = response?.flatMap((item) => item);
      },
      (erro) => console.error('Erro ao inserir menu: ' + erro)
    );
    this.isMobile = this.deviceService.isMobile();
    this.updateStatusMenu();
  }

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      name: node.name,
      route: node.route,
      host: node.host,
      level: level,
      expandable: !!node.children,
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }

  navigateToRoute(node: FileNode, ref: HTMLElement) {
    document
      .querySelectorAll('.style-mat-tree')
      .forEach((e) => e.classList.remove('selected'));
    ref.classList.add('selected');
    const iframe = document.querySelector('iframe');
    this.menuNavigationService.navigateToRoute(iframe, node.host, {
      action: 'routing',
      data: node.route,
      name: node.name,
    });
  }

  /* TODO(tulio) - Mudar isso de lugar */
  @HostListener('window:message', ['$event'])
  onMessage(message) {
    switch (message.data.action) {
      case 'changeURL':
        this.menuNavigationService.changeURLOnNavigation(message);
        break;
      case 'signOut':
        this.menuNavigationService.signOut();
        break;
    }
  }

  updateStatusMenu() {
    setTimeout(() => {
      this.workFlowStatusService.WorkFlowStatus.subscribe(
        (data: any[]) => {
          data.forEach((item) => {
            if (
              item.children !== null &&
              item.children !== undefined &&
              item.children.length > 0 &&
              item.title === 'cadastro'
            ) {
              this.statusList = this.statusList.concat(item.children);
            }
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
    }, 2000);

    return;
  }

  generatedId(nodeName: string): string {
    return nodeName.replace(/ /g, '').toLowerCase();
  }

  statusItem(node: FileNode) {
    const menuItemStatus: any = this.statusList.find((status: any) => {
      if (status.name == node.name) {
        return true;
      }
      return false;
    });

    if (menuItemStatus?.status === null) {
      return 'NOSTATUS';
    } else {
      return menuItemStatus?.status;
    }
  }

  exitToApp() {
    this.authService.logoutAndRemoveStorage();
    this.menuNavigationService.isLoggedIn = false;
  }
}
