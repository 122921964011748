import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

import { files } from '../layout/left-menu/example-data';
import { SellerPortalHosts } from './../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class MenuNavigationService {
  private _currentHost: string = null;
  private _currentRoute: string = null;
  private _defaultTitle: string = 'Parceiro Fast';
  private _spinnerEventEmmiter: EventEmitter<any>;
  public isLoggedIn: boolean = false;

  public isLoading = false;

  constructor(
    private _router: Router,
    private storageService: StorageService,
    private http: HttpClient
  ) {
    this._spinnerEventEmmiter = new EventEmitter();
    this._router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.filterIncomingURL(ev);
      }
    });
  }

  public getUserMenuItems() {
    const URL_MENU_SELLER = `${SellerPortalHosts.SELLERCENTER}/menu`;
    const URL_MENU_CADASTRO =
      SellerPortalHosts.CADASTRO +
      '/fastshop/catalog/1/menu'; /* /<tenantId>/catalog/<catalogId> */
    const URL_MENU_FMS = SellerPortalHosts.FMS + '/api/menu';
    const URL_MENU_FINANCEIRO = SellerPortalHosts.FINANCEIRO + '/menuFinance';

    const headers = new HttpHeaders({
      accept: 'application/json',
      'seller-api-version': 'v1',
      token: this.storageService.storage.getItem('key'),
    });

    const menuSellerCadastro: Observable<any> = this.http.get(URL_MENU_SELLER, {
      headers: headers,
    });

    const menuCadastro: Observable<any> = this.http
      .get(URL_MENU_CADASTRO, {
        headers: new HttpHeaders({
          token: this.storageService.storage.getItem('key'),
          'mpc-api-version': 'v1',
        }),
      })
      .pipe(
        timeout(10000)
      );

    const menuFms: Observable<any> = this.http
      .get(URL_MENU_FMS, {
        headers: new HttpHeaders({
          profile: this.storageService.storage.getItem('key'),
          apikey: ' 0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
        }),
      })
      .pipe(
        timeout(10000)
      );

    const menuFinanceiro: Observable<any> = this.http
      .get(URL_MENU_FINANCEIRO, {
        headers: new HttpHeaders({
          token: 'Giwo94Nj7Ol7qCsvquw3SEz2aS21yiWa',
          Authorization: `Bearer ${this.storageService.storage.getItem('key')}`,
        }),
      })
      .pipe(
        timeout(10000)
      );

    function tratamentoErro (menu: string, error: HttpErrorResponse){
      console.error(
        `Não foi possível inserir o menu ${menu}: ${error.message}`
      );
      return of([]);
    };

    return forkJoin([
      menuCadastro.pipe(catchError((error) => tratamentoErro('MPC', error))),
      menuSellerCadastro.pipe(
        catchError((error) => tratamentoErro('Seller Center', error))
      ),
      menuFms.pipe(catchError((error) => tratamentoErro('FMS', error))),
      menuFinanceiro.pipe(
        catchError((error) => tratamentoErro('Financeiro', error))
      ),
    ]).pipe(map(menu => menu.filter(menu => Array.isArray(menu))));
  }

  private filterIncomingURL(routingEvent) {
    const isUserLoggedIn: boolean =
      window.localStorage.getItem('isLoggedIn') === 'true';
    if (!isUserLoggedIn && this.isLoggedIn) {
      this._router.navigate(['/']);
      this.isLoggedIn = false;
      return;
    }

    const requestedURL = routingEvent.url;
    files.forEach((item) => {
      if (item.hasOwnProperty('route')) {
        const matched = item.route.match(requestedURL);
        if (matched) {
          this.navigateToRoute(document.querySelector('iframe'), item.host, {
            action: 'routing',
            data: item.route,
          });
          return;
        }
      }

      item.children?.forEach((child) => {
        let regexString = child.route;
        if (regexString !== '') {
          const regex = new RegExp('^.*' + regexString + '.*?');
          const matched = requestedURL.match(regex);
          if (matched) {
            this.navigateToRoute(document.querySelector('iframe'), child.host, {
              action: 'routing',
              data: child.route,
            });
            return;
          }
        }
      });
    });
  }

  public navigateToRoute(iframe: HTMLIFrameElement, host: any, message: any) {
    window.document.title = `${this._defaultTitle} | ${message.name}`;

    if (message.name === undefined) {
      window.document.title = `${this._defaultTitle} | Home`;
    }

    if (this._currentHost !== host) {
      this.changeIFrameSource(iframe, host, message);
    } else if (message.force == true) {
      this.changeIFrameSource(iframe, host, message);
    } else {
      this.sendMessageToHost(iframe, message);
    }
  }

  public setLocalData(iframe: HTMLIFrameElement) {
    let credentialsObj = {};

    for (let prop in window.localStorage) {
      credentialsObj[prop] = window.localStorage.getItem(prop);
    }

    /* Limpa as credênciais cacheadas antes de enviar as novas*/
    this.clearChildStorage(iframe);
    iframe.contentWindow.postMessage(
      { action: 'credentials', data: credentialsObj },
      '*'
    );
  }

  private clearChildStorage(iframe: HTMLIFrameElement) {
    iframe.contentWindow.postMessage({ action: 'clearCredentials' }, '*');
  }

  private sendMessageToHost(iframe: HTMLIFrameElement, message: any) {
    iframe.contentWindow.postMessage(message, '*');
  }

  private changeIFrameSource(iframe: HTMLIFrameElement, newHost, message) {
    this.isLoading = true;
    this._currentHost = newHost;
    if (iframe == null || iframe == undefined) {
      iframe = document.querySelector('iframe');
    }
    iframe.src = newHost;
    iframe.onload = () => {
      this.isLoading = false;
      this.setLocalData(iframe);
      this.sendMessageToHost(iframe, message);
    };

    iframe.onerror = () => {
      window.document.title = this._defaultTitle;
      this.isLoading = false;
    };
  }

  public changeURLOnNavigation(iframeMessage: any) {
    window.history.replaceState(null, null, iframeMessage.data.route);
  }

  public signOut() {
    if (this._currentHost) {
      this.clearChildStorage(
        window.document.querySelector('iframe') as HTMLIFrameElement
      );
    }
    this.storageService.storage.clear();
    window.location.href = '';
  }
}

