<div class="background-login">
    <div class="container">
        <div class="row justify-content-center">
            <div class="row left col-md-12">
                <div>
                    <img id="imageLoginPage" src="./../../../assets/fast_logo.svg" alt="imagem fast">
                </div>
            </div>
            <div class="left col-md-6 mb-2">
                <img src="./../../../assets/images/img_login_loja_fast.png" alt="imagem login" class="imgLogin" width="600" height="355">
            </div>
            <div class="right col-md-6 content-rigth-login">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>