<mat-toolbar class="header-style" id="headerStyle">
  <mat-toolbar-row *ngIf="!isMobile">
    <div class="menu-mob-icon" [class.active]="isMenuOpened" (click)="toggleMenu()" *ngIf="isMobile">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="logoHeader" id="logoHeader">
      <div>
        <img src="../../../assets/fast_logo.svg" alt="Logo">
      </div>
    </div>
    <div class="itemLabel" *ngIf="isAdminFastshop === false">
      <div *ngIf="allCompanyName.length === 1">
        <p id="companyName">{{companyName}}</p>
      </div>
      <div *ngIf="allCompanyName.length > 1">
        <mat-form-field>
          <mat-select (selectionChange)="setSellerID($event)" #selectGroupName>
            <mat-select-filter *ngIf="selectGroupName.focused" [placeholder]="'Sellers'" [displayMember]="'groupName'" [array]="groups"
              (filteredReturn)="filteredList5 =$event; filtraLista()"></mat-select-filter>
            <mat-option *ngFor="let item of filteredList5" [value]="item" id="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="itemLabel" *ngIf="isAdminFastshop === true">
      <mat-form-field>
        <mat-select (selectionChange)="setSellerID($event)" [(value)]="selected" #selectGroupName>
          <mat-select-filter *ngIf="selectGroupName.focused" [placeholder]="'Sellers'" [noResultsMessage]="'Nenhum seller encontrado'"
            [displayMember]="'groupName'" [array]="groups" (filteredReturn)="filteredList5 =$event; filtraLista()"></mat-select-filter>
          <mat-option *ngFor="let item of filteredList5" [value]="item.groupName" id="item.groupName">
            {{item.groupName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="spacer"></span>
    <div class="row usernameArea">
      <button mat-icon-button aria-label="User logo" id="personPin">
        <mat-icon>account_circle</mat-icon>
      </button>
      <span class="mt-3" id="tittleFastAdmin">{{userName}}</span>
    </div>
    <div class="containerSair">
      <button color="primary" mat-icon-button (click)="exitToApp()" class="buttonSair removeFocus" id="buttonSair">
      <p>Sair</p>
    </button>
    </div>
    <button mat-icon-button color="primary" (click)="exitToApp()" class="removeFocus" id="buttonExitToApp">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="isMobile">
    <div class="menu-mob-icon" [class.active]="isMenuOpened" (click)="toggleMenu()" *ngIf="isMobile">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div>
      <div class="itemLabel" *ngIf="isAdminFastshop === false">
        <div *ngIf="allCompanyName.length === 1">
          <p id="companyName">{{companyName}}</p>
        </div>
        <div *ngIf="allCompanyName.length > 1">
          <mat-form-field appearance="outline">
            <mat-select (selectionChange)="setSellerID($event)" [disableOptionCentering]="true" #selectGroupName>
              <mat-select-filter *ngIf="selectGroupName.focused" [placeholder]="'Sellers'" [displayMember]="'groupName'" [array]="groups"
                (filteredReturn)="filteredList5 =$event"></mat-select-filter>
              <mat-option *ngFor="let item of filteredList5" [value]="item" id="item">
                {{item}} 
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="itemLabel" *ngIf="isAdminFastshop === true">
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="setSellerID($event)" [disableOptionCentering]="true" [(value)]="selected" #selectGroupName>
            <mat-select-filter *ngIf="selectGroupName.focused" [placeholder]="'Sellers'" [noResultsMessage]="'Nenhum seller encontrado'"
              [displayMember]="'groupName'" [array]="groups" (filteredReturn)="filteredList5 =$event"></mat-select-filter>
            <mat-option *ngFor="let item of filteredList5" [value]="item.groupName" id="item.groupName">
              {{item.groupName}} 
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row" id="logoHeaderMobile">
      <div>
        <img src="../../../assets/svg/logo_parceiro_fast.svg" alt="Logo">
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="isMobile">
    <div class="mobileName">
      <small class="" id="userName"> {{userName}}</small>
    </div>
  </mat-toolbar-row>
</mat-toolbar>